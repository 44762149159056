import React, { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


const ActionsCell = ({ id }) => {
 // const navigate = useNavigate();
  const handleEditClick = () => {
    console.log("Edit button clicked for row with ID:", id);
 //   navigate(`/update-Branch/${id}`);
  };
  return (
<Button
      variant="contained"
      style={{backgroundColor:'#2CC4CB'}}
      startIcon={<EditIcon />}
      onClick={handleEditClick}
      size="small"
>
      Edit
</Button>
  );
};

function Part_no() {
  const [articles, setArticles] = useState([
    { id: 1, sn: 1, name: 'Article 1', description: 'Description 1' },
    { id: 2, sn: 2, name: 'Article 2', description: 'Description 2' },
    // Add more articles as needed
  ]);

  const [selectedArticles, setSelectedArticles] = useState([]);
  const [alert, setAlert] = useState('');

  const handleSelectArticle = (id) => {
    setSelectedArticles((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = () => {
    if (selectedArticles.length === 0) {
      setAlert('No articles selected for deletion.');
    } else {
      setArticles((prevArticles) =>
        prevArticles.filter((article) => !selectedArticles.includes(article.id))
      );
      setSelectedArticles([]);
      setAlert('');
    }
  };

  const columns = [
    { field: 'id', headerName: 'SN', flex:1},
    { field: 'Consignor Name	', headerName: 'Consignor Name	', flex:1 },
    { field: 'Consignor Part Number	', headerName: 'Consignor Part Number	',flex:1},
    { field: 'Consignee Name', headerName: 'Consignee Name	',flex:1},
    { field: 'Consignee Part Number	', headerName: 'Consignee Part Number	',flex:1},
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      renderCell: (params) => (
        <input type="checkbox" onChange={() => handleSelectArticle(params.row.id)} checked={selectedArticles.includes(params.row.id)} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => <ActionsCell id={params.id} />,
    },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Add_Part_No');
  };

  
  return (
    <>
    <div style={{ backgroundColor: '#f5f5f5', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ marginBottom: '20px', textAlign: 'center', fontSize: '24px', fontFamily: 'Poppins, sans-serif' }}>Part Number List</div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <button 
    style={{ 
      backgroundColor: '#20c997', 
      border: 'none', 
      padding: '10px 20px', 
      borderRadius: '50px', 
      color: '#fff', 
      textAlign: 'left', 
      marginLeft: '0', 
      display: 'block', 
      width: 'auto' 
    }}
    onClick={handleClick}
  >
    Add Part Number
  </button>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Search style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '50px', padding: '2px 20px', marginRight: '1%', backgroundColor: 'white' }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              style={{ width: '150px' }}
            />
          </Search>
          <button onClick={handleDelete} style={{ padding: '5px 10px', color: 'white', background: '#5c6bc0', borderRadius: '50px', border: '1px solid transparent', boxShadow: '0 5px 20px #d6dee4', cursor: 'pointer' }}>Delete</button>
        </div>
      </div>
      {alert && <div style={{ color: 'orange', textAlign: 'center', marginBottom: '20px' }}>{alert}</div>}
      <div style={{ height: 800, width: '100%' }}>
     
        <DataGrid
          rows={articles}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[ 20]}
          disableSelectionOnClick
        />
      </div>
    
    </div>
  </>
  )
}

export default Part_no ;