export const APP_BASE_PATH = 'http://localhost:5300/';
export const DC_BASE_PATH = 'http://localhost:5300/api/transport';
export const DOCKET_BASE_PATH = 'http://localhost:5400/api/docket';






// export const APP_BASE_PATH = 'https://api.omsairamlogistics.in/';
// export const DC_BASE_PATH = 'https://api.omsairamlogistics.in/api/transport';
// export const DOCKET_BASE_PATH = 'https://pod.omsairamlogistics.in/api/docket';

