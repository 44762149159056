import { useEffect, useState} from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import web from '../images/web.png';
import tnb_logo_01 from '../images/tnb_logo_01.png'
import logo from "../images/logo.png";
import "./Login.css";
import LoadingSpinner from '../ui/LoadingSpinner';
import { getLrFile } from '../../lib/api-getLr';
import CustomSnackbar from '../ui/SnackbarComponent';
import axios from 'axios';



const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [lrNumber, setLrNumber ] = useState(null)
  const [confirmmessage,setConfirmmessage] = useState("")
  const [isConfirmationopen,setConfirmationopen] = useState(false)
  const [open, setOpen] = useState(false)






  const handleClose = ()=>{
    setOpen(false)
    }


    

const handleTrackPOD = async()=>{
  const Hyperlink = `https://podfiles.omsairamlogistics.in/pod/${lrNumber}.jpg`;
  window.open(Hyperlink, '_blank');
  setLrNumber('')
}


const handleTrackLR = async()=>{
 setIsLoading(true);
  const response = await axios.post('https://ws.omsairamlogistics.in/api/transport/getlrtrackingReport',{lrno:lrNumber})
  if(response.status == 200){
    setLrNumber('');
    setIsLoading(false);
    const Hyperlink = response.data;
    window.open(Hyperlink, '_blank');
  }else{  
    setConfirmationopen(true);
    setConfirmmessage("Invalid LR Number !!")
    setLrNumber('')
  }
  setLrNumber('')
}



  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img
              src={tnb_logo_01}
              alt="Transporter NoteBook"
              className="imgontop"
            />
          </div>
        </div>
      </div>
      <section className="sectionimage" style={{ height: "93dvh" }}>
        <div className="px-4 py-5 px-md-5 text-center text-lg-start">
          <div className="container">
            <div className="row gx-lg-5 align-items-center down">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <h1 className="my-4 display-6 h1 ls-tight">
                Welcome to the Transporter NoteBook Digital ERP System
                </h1>
                <h4 className="divider h4 ls-tight">
                  Facilitating Transport, Enabling Growth
                </h4>
                <p className="p">
                  Economic growth critically depends on efficient transportation
                  systems. TNB makes all the data and processes in multiple
                  branches across India, completely digital, safe, and
                  accessible from anywhere
                </p>
              </div>

              <div className="col-lg-6 mb-5 mb-lg-0 form">
                <div className="card">
              
                  <Box 
        textAlign="center" 
        bgcolor="rgba(255, 255, 255, 0.8)" 
        p={{ xs: 2, sm: 10 }} 
        borderRadius={2} 
        width={{ xs: '90%', sm: 'auto' }} // Adjusting width responsively
>
<Grid item xs={12}>
<TextField
            className="customTextField"
            name="Enter LR/POD Number"
            label="Enter LR/POD Number"
            fullWidth
            variant="outlined"
            size="medium"
            onChange={(event, value) => setLrNumber(event.target.value || null)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
              },
              '& .MuiInputBase-input': {
                padding: '15px',
              }
            }}
            style={{ marginBottom: '20px' }}
            InputProps={{
              startAdornment: (
<InputAdornment position="start">
<Search sx={{color:'blue'}}/>
</InputAdornment>
              ),
            }}
          />
</Grid>
<Box display="flex" justifyContent="center" flexWrap="wrap" gap="20px" marginTop="20px">
<Button variant="contained" color="primary" onClick={handleTrackLR} style={{ borderRadius: '50px', padding: '10px 30px', fontSize: '15px' }}>
            Track LR
</Button>
<Button variant="contained" color="primary" onClick={handleTrackPOD} style={{ borderRadius: '50px', padding: '10px 30px', fontSize: '15px' }}>
            Track POD
</Button>
</Box>
<Typography
          variant="body2"
          color="primary"
          style={{ margin: '0px auto' ,marginTop:16,cursor: 'pointer', fontSize: '20px', textDecoration: 'underline', fontFamily: 'Poppins' }}
        >
          Back to Home
        </Typography>
</Box>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: "10px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <img src={web} alt="Transporter NoteBook Website" height="50%" />{" "}
              &nbsp;
              <a
                href="https://transporternotebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                Transporter Note Book
              </a>
            </div>
            <div className="col-lg-3">
              Powered by <a href="https://www.vspace.in">vspace.in</a> software
            </div>
            <div className="col-lg-2">
              Email <a href="mailto:tnb@vspace.in">tnb@vspace.in</a>
            </div>
          </div>
        </div>
      </section>



      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={1200}
      >
      <DialogTitle>
      Payment Receipt
        </DialogTitle>
        {/* <iframe title="pdf-view"   src={pdfData ? `${APP_BASE_PATH}${pdfData}` : newFile ? newFile : null}
        width="800px" height="800px"></iframe> */}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>          
        </DialogActions>
      </Dialog>
      <CustomSnackbar
      open={isConfirmationopen}
      message={confirmmessage}
      onClose = {()=> setConfirmationopen(false)}
      color={'error'}
      />



    </>
  );
};

export default Login;