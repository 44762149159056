import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import {   Typography, Grid, IconButton, Badge, Modal, Box, Button, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../images/logo1.png'
import './header.css';
import { NavLink, useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskIcon from '@mui/icons-material/Task';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import dayjs from 'dayjs';
import { getFilesCount, updateDocketNumber } from '../../lib/api-docket';
import LoadingSpinner from '../ui/LoadingSpinner';
import CustomSnackbar from '../ui/SnackbarComponent';



const style = {
    position: 'absolute',
    fontFamily: 'roboto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '1%',
    boxShadow: 24,
    p: 4
  };

function Header(){
const [count, setCount] = useState(0)
const [open, setOpen] = useState(false)
const [isLoading, setIsLoading] = useState(false)
const [confirmmessage,setConfirmmessage] = useState("")
const [isConfirmationopen,setConfirmationopen] = useState(false)





const countSetter = async()=>{
const getCount = await getFilesCount(); 
setCount(getCount.data.count)
}


useEffect(()=>{
countSetter();
},[])



const handleLrUpdate = async()=>{
    setIsLoading(true)
    const response = await updateDocketNumber();
    if(response.status == 200){
        countSetter();
        setConfirmmessage(response.data.message);
        setConfirmationopen(true);
        setOpen(false)
        setIsLoading(false)
    }else{
        countSetter();
        setConfirmmessage(response.data.message);
        setConfirmationopen(true);
        setOpen(false)
        setIsLoading(false)
    }
}


const handleOpen = ()=>{
setOpen(true)
}


const handleClose = ()=>{
setOpen(false)
}


    return(
            <>
            {isLoading && <LoadingSpinner />}
<nav className="navbar1 navbar1-expand-lg navbar1-light">
<Grid container justifyContent="space-between" alignItems="center" sx={{margin:'0px 20px 0px 0px',padding:'0px 15px 15px',height:'75px'}}>
<Grid item>
    <img src={logo} alt='' id="logo"/>
</Grid>
       
          <Grid item sx={{marginLeft:'-56px'}}>
            <div id='marginicon'>
           <Typography variant="h6"><NavLink id="title" to="/index"><span id="icon">OM SAIRAM LOGISTICS</span></NavLink>
            <span id="icons">OM SAIRAM LOGISTICS PLOT NO-E7B/4, MIDC, PHASE-III, CHAKAN INDUSTRIAL AREA, TAL-KHED, DIST. PUNE-410501</span></Typography>
         </div> </Grid>
         <Grid item>
            <Typography >
            <span id="icons-details">Help Line / IT Support<br/>
             Contact No: +919112293931/+919209054594<br/>
             Email: - it@omsairamlogistics.com</span></Typography>
        </Grid>
        <Grid item>
        <span id="icons1"> 
            <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge></span>
        </Grid>
        <Grid item>
        <Tooltip title="Update Docket Number">
        <IconButton onClick={handleOpen}>
        <span id="icons1"> 
        <Badge badgeContent={count} color="error">
            <TaskIcon />
          </Badge></span>
        </IconButton>
        </Tooltip>
        </Grid>
         <Grid item>
            <div id='marginicon1'>
            <Typography variant="h6"><span id="icons11">Welcome, superadmin Date: {dayjs(new Date()).format('DD-MM-YYYY')}</span>

            <NavLink to="/" id='hover1' sx={{ textDecoration:'none'}}><span id="icons1">  <LogoutIcon sx={{ display: { xs: 'none', md: 'flex',variant:'h4', fontSize:'large' ,fontWeight: 900,}, mr:0.7 }} />Logout</span></NavLink></Typography>
         </div> </Grid>
        </Grid>
            </nav>



            <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
              Are You want to Update Docket Number ?
            </Typography>
            <br />
            <Button
              variant="contained"
              color='success'  
              sx={{ marginRight: 1 }}
              onClick={handleLrUpdate}
            >
              Yes
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <CustomSnackbar
      open={isConfirmationopen}
      message={confirmmessage}
      onClose = {()=> setConfirmationopen(false)}
      color={'error'}
      />
            </>
    )
}
export default Header