import React from 'react'
import Header from '../header/Header'
import Navbar from '../navbar/Navbar'
import CustomRoutes from '../../routes/Routes'
import Footer from '../footer/Footer'
import classes from './Layout.module.css';

const Layout = () => {
  return (
    <>
    <Header/>
     <Navbar/>
     <main className={classes.main_content}>
    <CustomRoutes />
    </main>
    <Footer/>
    </>
  )
}

export default Layout
